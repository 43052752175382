// nav
.max-width-nav {
    @media(max-width:990px) {
        display: none;
    }
}

.min-width-nav {
    @media(min-width:990px) {
        display: none;
    }
}

.navbar-finket {
    box-shadow: 0 0 15px rgba(172, 4, 116, 0.7);

    a {
        margin: .5rem 0 .2rem .2rem !important;
        color: $black;
    }

    .btn-desktop {
        background-color: $magenta;
        border-radius: 2rem;
        margin-left: 20px;

        button {
            color: $white !important;
        }
    }

    background-color:$white !important;

    @media(max-width:760px) {
        padding: .5rem 0 .5rem 0 !important;

        .btn-menu {
            width: 5rem;
        }
    }
}

.ic-menu {
    width: 35px;
    height: 35px;
    margin: 0.7rem 0
}

.logo-nav {
    width: 9rem;
    @media(max-width:760px) {
        width: 6.5rem;
        padding-left: 1rem;
    }
}

.logo-nav-lg {
    width: 10rem;
    margin-left: 50px;
    @media(max-width:760px) {
        width: 6.5rem;
        padding-left: 1rem;
        margin-left: 0;
    }
}

// hero header
.max-width-header {
    @media(max-width:770px) {
        display: none;
    }
}

.min-width-header {
    @media(min-width:770px) {
        display: none;
    }
}

.responsive-header {
    @media(max-width: 770px) {
        padding: 6rem 0rem 4rem 0rem !important;

        .img-h-r {
            max-width: 99% !important;
        }

        overflow-x: hidden;
    }
}

.m-text-r {
    margin: 1.5rem 1rem 3rem 34px;
}

.hero-header {
    padding: 2rem 0rem 2rem 0rem;

    img {
        max-width: 100%;
        right: 0px;
        bottom: 0px;
    }

    overflow-x: hidden;

    @media(max-width:1199px) {
        margin-top: 50px;
    }
}

.color-white {
    color: $white;
}

.color-black {
    color: $black;
}

.color-yellow {
    color: $yellow;
}

.text-header {
    width: 45%;
    color: $white;
    padding-bottom: 1rem;
    padding-left: 40px;

    @media(max-width: 770px) {
        width: 100%;
    }

    @media(max-width: 1199px) {
        padding-top: 80px;
    }

    .title {
        color: $white;
    }

    .title-yellow {
        color: $yellow;
    }

    .title-black {
        color: $black;
    }

    .title-magenta {
        color: $magenta;
    }
}

.header_register-text {
    @media(max-width: 1199px) {
        font-size: medium !important;
    }
}

//- About

.about-finket {
    color: $white;
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;

    @media (max-width:760px) {
        p {
            font-size: large !important;
        }
    }
}

// HowToRegister
.title-screen {
    padding-top: 150px;
    font-size: 2.0736rem;

    @media(max-width:720px) {
        font-size: 1.7rem !important;
    }
}

.register-img {
    display: flex;

    @media(min-width:720px) {
        @media(max-width:1000px) {
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin: 0 !important;
            padding: 0 !important;

            img {
                max-width: 100%;
            }

            overflow-x: hidden;
        }
    }

    @media(max-width:720px) {
        flex-direction: column;

        img {
            max-width: 50%;
        }

        overflow-x: hidden;
    }
}

.text-register {
    margin: 2rem auto 2rem;
    font-size: 1.3rem;
    color: $black;

    @media(min-width:768px) {
        @media(max-width:1000px) {
            width: 100% !important;
            font-size: 1.1rem !important;
            padding: .5rem .5rem 1rem 0 !important;
        }
    }

    @media(max-width:768px) {
        font-weight: normal !important;
        font-size: 0.88rem !important;
        padding: 0rem .5rem .5rem .5rem;
        margin: 0rem 5rem 2rem 5rem;

        b {
            font-weight: 400 !important;
        }

        p {
            font-weight: 400 !important;
        }

    }
}



//Carousel-functionalities

.carousel-desk {
    padding-top: 100px;
    padding-bottom: 50px;
    padding-left: 130px;
    padding-right: 130px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 1535px;
    margin: auto;
    @media(max-width:767px) {
        display: none !important;
    }
}

.carousel-item_container {
    margin-right: 0px;
    margin-left: 0px !important;
    justify-content: center;
    align-items: center;

    @media (max-width: 992px) {
        flex-direction: column;
      }
      
}

.carousel-item_card {
    max-width: 620px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;

    @media (max-width: 992px) {
        width: fit-content !important;
      }
}

.carousel_wrapper {
    display: flex;
    flex-direction: column;
}

.carousel-item-width {
    width: fit-content !important;
}

.carousel-mobile_item-card {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.carousel-mobile {
    @media(min-width:767px) {
        display: none !important;
    }
}

//Carousel-selectors
.selectors [data-bs-target] {
    border-radius: 100%;
    background-color: #ac0474 !important;
    width: 10px;
    height: 10px;
    margin: 0 6px;
    ;
}

.selectors .active {
    background-color: #ac0474 !important;
}

// - Video
.main-video {
    height: 71vw;
    width: 100%;
    background-image: url("../../../assets/img/illustrations/back-video-desktop.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-position-y: 0rem;

    @media(max-width:720px) {
        height: 80vw;
        background-image: url("../../../assets/img/illustrations/back-video-mobile.png");
        margin-bottom: 80px !important;
    }
}

.btn-download-container {
    display: flex;
    width: auto;
    align-items: center;
    justify-content: space-around;

    @media(max-width:991px) {
        gap: 10px;
    }
}

.video-box {
    width: 100%;
    max-width: 71vw;
    height: auto;
    margin: 5px auto;
    // transform: rotate(0.25turn);

    @media(max-width:1200px) {
        max-width: 70vw;
    }

    @media(max-width:720px) {
        max-width: 90vw;
    }
}

.video {
    position: relative;
    // width: 1272px;
    // height: 716px;
    padding-top: 56.25%;
}

.video iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.rounded-video {
    padding: 1.2rem;
    border-radius: 1.5rem;

    iframe {
        border-radius: 1rem;
    }

    @media(max-width:1200px) {
        padding: 0.9rem;
        border-radius: 0.9rem;

        iframe {
            border-radius: 0.8rem;
        }
    }

    @media(max-width:770px) {
        padding: 0.7rem;
        border-radius: 0.7rem;

        iframe {
            border-radius: 0.75rem;
        }
    }
}



//- Segurities
.segurity-img {
    width: 6rem;
}

.segurity-picture {
    margin: 0rem 0 0 0rem !important;
    width: 28rem;

    p {
        display: none;
        font-size: 1.2rem !important;
    }

    @media(max-width:768px) {
        margin: 0rem 0 2rem 0rem !important;
        width: auto;

        p {
            display: block;
        }
    }
}

.sec-tittle {
    text-align: center !important;
    margin-top: 2rem !important;
    margin-bottom: 1.5rem !important;

    p {
        display: block;
        font-size: 1.5rem !important;
    }

    @media(min-width:768px) {
        h1 {
            width: 50%;
            margin: auto;
        }
    }
}


.margin-sec {
    @media(max-width:768px) {
        margin-bottom: 2rem;
    }

}


.segurity-all {
    display: flex;
    justify-content: center;

    @media(max-width:768px) {
        display: flex !important;
        align-items: center !important;
        margin: auto;

        p {
            text-align: center !important;
            width: 100% !important;
            margin: auto !important;
        }

        h1 {
            margin: auto !important;
            padding-bottom: .5rem;
        }
    }
}

//- footer
.footer-desk {
    @media(max-width:769px) {
        display: none;
    }
}

.footer-mobile {
    @media(min-width:769px) {
        display: none;
    }
}

.margin-icons{
    margin-left: 2.25rem !important;
    @media(min-width:991.5px) {
        margin-left: 1rem !important;
    }
    @media(min-width:1199.5px) {
        margin-left: 5rem !important;
    }
    @media(min-width:1539.5px) {
        margin-left: 16.5rem !important;
    }
}
.pad-icons{
    margin-bottom: 35.82px;
}
.logo-footer {
    img {
        width: 8rem;
    }
    :first-child {
        margin-right: 50px;
    }

    padding-bottom: 2rem;
    text-align: center;

    @media(max-width:991.5px) {
        :first-child {
            margin-right: 43px !important;
        }
    }

    @media(max-width:1199.4px) {
        display: flex;
        padding-bottom: 0rem;

        :first-child {
            margin-right: 20px;
        }

        img {
            width: 8rem;
            padding-bottom: 2rem;
        }
    }


    @media(max-width:760px) {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        :first-child {
            margin-right: 0px !important;
        }
    }
}

.logo-google {
    text-align: center;

    img {
        width: 10rem;
    }

    padding: 2rem 0 2rem 0;
}

.icons-footer {
    display: flex;
    align-items: flex-end;
}

.text-footer {
    @media(max-width:720px) {
        ul {
            margin: 0 !important;
            display: flex !important;
            flex-direction: column !important;
        }

        li {
            padding: .3rem;
        }
    }
}

.text-footers {
    margin: auto;
    margin-top: 1rem;

    @media(max-width:720px) {
        p {
            text-align: center;
        }

        a {
            padding: .3rem;
        }
    }
}

.chevron-right {
    background: none;
    box-sizing: border-box;
    position: relative;
    display: block;
    transform: scale(var(--ggs,1));
    width: 40px;
    height: 40px;
    border: 5px solid transparent;
    border-radius: 100px
}
.chevron-right::after {
    content: "";
    display: block;
    box-sizing: border-box;
    position: absolute;
    width: 20px;
    height: 20px;
    border-bottom: 5px solid;
    border-right: 5px solid;
    transform: rotate(-45deg);
    right: 6px;
    top: 4px;
    color: #B61989;
}

.chevron-left {
    background: none;
    box-sizing: border-box;
    position: relative;
    display: block;
    transform: scale(var(--ggs,1));
    width: 40px;
    height: 40px;
    border: 5px solid transparent;
    border-radius: 100px
}
.chevron-left::after {
    content: "";
    display: block;
    box-sizing: border-box;
    position: absolute;
    width: 20px;
    height: 20px;
    border-bottom: 5px solid;
    border-right: 5px solid;
    transform: rotate(135deg);
    left: 6px;
    top: 4px;
    color: #B61989;
}

.carousel_chevrons-container {
    display: flex;
    align-items: center;
    justify-content: center;

    @media(min-width:1499px) {
        width: 1380px;
    }
}

.carousel-item_text-container {
    @media(max-width:1499px) {
        padding-left: 7rem;
    }

    @media(max-width:990px) {
        padding-left: 2rem;
    }
}

