/*-----------------------------------------------
|   LaslesVPN glow buttons
-----------------------------------------------*/
.btn-glow {
  position: relative;
  &::before{
    content: '';
    height: 80%;
    width: 80%;
    position: absolute;
    top: 10%;
    left: 10%;
    display: inline-block;
    background-color: inherit;
    opacity: 0.55;
    filter: blur(26px);
    transform: translateY(45%);
    z-index: -1;
    border-radius: 10px;
  }
}
.btn-google{
  border:none !important;  
  padding: 0 !important;
  margin: 0 !important;
  width: 12rem !important;
  border-radius: 2rem !important;
  
  @media(max-width:770px){
    width:13rem !important;
    img{
      max-width: 100%;
    }
  }
}

.btn-footer{
  border:none !important;  
  padding: 0 !important;
  margin: 0 !important;
  width: 12rem !important;
  border-radius: 2rem !important;
  
  @media(max-width:770px){
    margin-left: 5.3rem!important;
    img{
      max-width: 100%;
    }
  }
}

.btn-repentance{
  background-color: white;
  border-radius: 50px;
  padding: 10px;
  white-space: nowrap;
}

.btn-about{
  img{
    width:100%;
  }
  border:none !important;  
  padding: 2rem 0 0 0 !important;
  margin: 0 !important;
  width: 14rem !important;
  border-radius: 2rem !important;
}
.btn-download-app {
  font-weight:900;
  margin-top: 5px;
  border: none;
  background-color: transparent;
}