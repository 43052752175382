.info-container{
    margin-top: 90px;
    width:100%;
    padding:5rem;
  }

  .info-container h1 {
    font-size: 2.5rem;
    font-weight: 700;
    color: #333;
    margin-bottom: 20px;
  }

.info-section {
    padding: 2rem 5rem;
    margin-top: 20px;
}

.img-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
}

.sub-title {
    margin-top: 30px;
    margin-bottom: 30px;
    width: 100%;
}

.link-title {
    margin-top: 40px;
}

.info-link {
    color: blue;
}

.flex-div {
    display: flex;
    gap: 5px;
}

@media screen and (max-width: 400px) {
    .info-container {
        padding: 2rem;
    }

    .info-section {
        padding: 1rem;
    }

    .img-container {
        margin-top: 20px;
    }
    
    .info-img {
        width: 100%;
    }
}