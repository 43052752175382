@font-face {
	font-family: 'Raleway';
	src: url('../fonts/Raleway-MediumItalic.ttf');
	src: local('Raleway Medium Italic'), local('Raleway-MediumItalic'), url('../fonts/Raleway-MediumItalic.ttf') format('truetype');
	font-weight: 500;
	font-style: italic;
}


@font-face {
	font-family: 'Raleway';
	src: url('../fonts/Raleway-Black.ttf');
	rc: local('Raleway Black'), local('Raleway-Black'), url('../fonts/Raleway-Black.ttf') format('truetype');
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: 'Raleway';
	src: url('../fonts/Raleway-Medium.ttf');
	rc: local('Raleway Medium'), local('Raleway-Medium'), url('../fonts/Raleway-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Raleway';
	src: url('../fonts/Raleway-Regular.ttf');
	rc: local('Raleway Regular'), local('Raleway-Regular'), url('../fonts/Raleway-Regular.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Raleway';
	src: url('../fonts/Raleway-Bold.ttf');
	rc: local('Raleway Bold'), local('Raleway-Bold'), url('../fonts/Raleway-Bold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Raleway';
	src: url('../fonts/Raleway-BlackItalic.ttf');
	rc: local('Raleway BlackItalic'), local('Raleway-BlackItalic'), url('../fonts/Raleway-BlackItalic.ttf') format('truetype');
	font-weight: 900;
	font-style: italic;
}

@font-face {
	font-family: 'Raleway';
	src: url('../fonts/Raleway-BoldItalic.ttf');
	rc: local('Raleway BoldItalic'), local('Raleway-BoldItalic'), url('../fonts/Raleway-BoldItalic.ttf') format('truetype');
	font-weight: bold;
	font-style: italic;
}


