.terms-and-conditions{
    padding: 6rem 0 1rem 7rem;
    h1{
        padding: 1rem 0 2rem 8rem;
    }
    @media (max-width:1024px) {
        flex-direction: column;
        padding: 6rem 0 0 0 !important;
        margin: 0 !important;
        max-width: 100% !important;
        text-align: center;
        h1{
            padding: 0!important;
        }
    }
}
.padding-terms{
    display:flex;
    padding-left: 7rem;
    align-items: center;
    @media (max-width:1024px) {
        flex-direction: column;
        padding: 0 !important;
        margin: 0 !important;
        max-width: 100% !important;
    }
}
.card-terms{    
    border: none !important;
    max-width: 15rem !important;
    @media (max-width:768px) {
        margin: 0 !important;
        max-width: 13rem !important;
    }
    .card-img-overlay{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .card-img-terms{
        width: 15rem !important;
        padding: 15px;
    }
    .card-img-select{
        width: 15rem !important;
        display:none;
        padding: 10px;
    }
    .card-icon{
        margin: .5rem;
        width: 2.3rem !important;
    }
    a{
        color: $black !important;
    }
    .title{
        font-size: 1rem;
    }
    .terms-cards{
        margin: 0 !important;
    }
}
.card-terms:hover{
    .card-img-terms{
        padding: 0;
        transition: 1.5s;
    }
    .card-img-select{
        padding: 0;
        transition: 1.5s;
    }
    .title{
        font-size: 1.1rem;
        transition: 1.5s;
    }
    .card-icon{
        width: 3rem !important;
        transition: 1.5s;
    }
}
.terms-text{
    display:none;
    .terms-text-body{
        padding: 0 15rem 0 15rem;
        text-align: justify;
        .title-txt{
            text-align: left !important;
        }
        a {
            color: $color-contrast-dark !important;
        }
        @media (max-width:780px) {
            padding: 2rem !important;
            margin: 0 !important;
        }
    }
}
.img-terms-table{
    max-width: 90%;
}
.select-terms{
    display:flex !important;
}

// footer

.footer-terms{
    display:flex;
    justify-content: space-between;
    background-color: $magenta;
    padding: 2rem 2rem 0 2rem;
}
@media screen and (max-width:768px) {
    .card-terms{
        max-width: 14rem !important;
        .card-img-terms{
            padding: 7px;
            max-width: 12rem !important;
        }
        .card-img-select{
            max-width: 12rem !important;
            padding: 5px; 
        }
        .card-icon{
            width: 2.5rem !important;
            margin: 0 !important;
            margin-top: 5px !important;
        }
        .title{
            font-size: 0.7rem !important;
            padding: 0 5px;
            margin: 0 !important;
        }
    }
    .terms-text-body{
        padding: 0 20px !important;
        .title-txt{
            text-align: left !important;
        }
    }
    .card-terms:hover{
        .card-img-terms{
            padding: 0;
            transition: 1s;
        }
        .card-img-select{
            padding: 0;
            transition: 1s;
        }
        .title{
            font-size: 0.8rem !important;
            transition: 1s;
        }
        .card-icon{
            width: 3rem !important;
            transition: 1s;
        }
    }
}
@media screen and (min-width:768px) and (max-width:1280px) {
    .padding-terms{
        flex-direction: row;
        padding: 0 0 0 7rem !important;
    }
    .terms-and-conditions{
        h1{
            text-align: left;
            padding: 1rem 0 2rem 8rem !important;
        }
    }
    .card-terms{
        max-width: 14rem !important;
        .card-img-terms{
            padding: 7px;
            max-width: 12rem !important;
        }
        .card-img-select{
            max-width: 12rem !important;
            padding: 5px; 
        }
        .card-icon{
            width: 2.5rem !important;
            margin: 0 !important;
            margin-top: 5px !important;
        }
        .title{
            font-size: 0.7rem !important;
            padding: 0 5px;
            margin: 0 !important;
        }
    }
    .terms-text-body{
        padding: 2rem 7rem !important;
        width: auto;
        .title-txt{
            text-align: left !important;
        }
    }
    .card-terms:hover{
        .card-img-terms{
            padding: 0;
            transition: 1s;
        }
        .card-img-select{
            padding: 0;
            transition: 1s;
        }
        .title{
            font-size: 0.8rem !important;
            transition: 1s;
        }
        .card-icon{
            width: 3rem !important;
            transition: 1s;
        }
    }
}