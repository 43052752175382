.complaints-container{
    padding: 0 5rem;
    margin-bottom: 40rem;
    margin-left: 10rem !important;
    margin-right: 10rem !important;
}
.title-complaints{
    font-size: 2.98598rem !important;
    font-weight: 600;
}
.mt-3-complaints{
    margin-top: 1rem !important;
    display: flex;
    justify-content: center;
}
.txt-complaints{
    display: flex;
    flex-direction: column;
    width: 70%;
    font-size: 1.44rem;
    font-weight: 600;
    b{
        margin-left: 10px;
    }
}
.txt-container{
    margin-top: 3.5rem !important;
}

.btn-whatsapp{
    margin-top: 5px;
}
.btn-whatsapp:hover{
    text-decoration: none;
    color: $white;
    margin-top: 0;
    transition: all 0.5s ease-in-out;
}
.btn-title{
    background-color: $magenta;
    text-decoration: none;
    color: $white;
    border-radius: 50rem;
    width: 20rem;
    height: 3rem;
    font-size: 1.3rem;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
}
.complaints-illustration{
    height: 30rem;
}

@media screen and (min-width: 1024px) and (max-width:1440px) {
    .txt-complaints{
        width: 90% !important;
    }
    .txt-container{
        margin-top: 3.5rem !important;
    }
    .complaints-container{
        padding: 0;
    }
    .mt-3-complaints{
        margin-left: 0;
    }
}
@media screen and (max-width:1024px) {
    .txt-complaints{
        width: 100%;
    }
    .txt-container{
        margin-top: 3.5rem !important;
    }
    .complaints-container{
        padding: 0;
        display: flex;
        justify-content: center;
        margin-left: 5rem !important;
        margin-right: 5rem !important;
        margin-bottom: 20rem;
    }
    .mt-3-complaints{
        margin-left: 0;
    }
    .img-container{
        width: 100%;
        display: flex;
        justify-content: center;
    }
}
@media screen and (max-width:768px) {
    .txt-complaints{
        width: 100%;
    }
    .complaints-container{
        padding: 0;
        display: flex;
        justify-content: center;
        margin-left: 5rem !important;
        margin-right: 5rem !important;
        margin-bottom: 10rem;
    }
    .mt-3-complaints{
        margin-left: 0;
        flex-direction: column;
    }
    .complaints-illustration{
        display: flex !important;
        position: inherit !important;
        margin-top: 5rem;
        height: 20rem;
    }
    .img-container{
        width: 100%;
        display: flex;
        justify-content: center;
    }
    .btn-title{
        width: 18rem;
    }
}