.faqs-container{
  display: flex;
  justify-content: center;
}

.faqs-section {
    min-height: calc(100vh - 555.35px);
    display: flex;
    align-items: center;
    justify-content: center;
}

.outside-question{
    font-size: 1.3rem;
    color: $black;
}
.container-faqs{
    font-size: 1.1rem;
    padding: 0 0 !important;
    width: 55%;
    margin: 0 auto;
    margin-bottom: 5rem;
    overflow: hidden;

}
.cardsFAQs{    
  border: none !important;
  max-width: 15rem !important;
  margin: 0 30px;
  .card-img{
      width: 15rem !important;
      padding:15px;
  }
  .card-img-select{
      width: 15rem !important;
      display:none;
      padding:10px;
  }
  .card-icon{
      margin: .5rem;
      width: 2.3rem !important;
  }
  .card-img-overlay{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
  }
  a{
      color: $black !important;
  }
  .title{
      font-size: 1rem;
  }
}
.cardsFAQs:hover{
  .card-img{
      padding:0;
      transition: 1.5s;
  }
  .card-img-select{
      padding:0;
      transition: 1.5s;
  }
  .card-icon{
      width: 3rem !important;
      transition: 1.5s;
  }
  .title{
      font-size: 1.1rem;
      transition: 1.5s;
  }
}
.faqs-text{
  display:none;
}
.select-faqs{
  display:flex !important;
}

.centerplease {
margin: 0 auto;
max-width: 270px;
font-size: 40px;
}

.question {
position: relative;
font-weight: bold !important;
margin: 0;
padding: 10px 10px 10px 10px;
display: block;
width: 100%;
cursor: pointer;
border-bottom: solid 1px #d6d6d6;
}

.answers {
margin: 5px 0 0 1rem;
max-height: 0;
z-index: 0;
position: relative;
opacity: 0;
-webkit-transition: .7s ease-in-out;
-moz-transition: .7s ease-in-out;
-o-transition: .7s ease-in-out;
transition: .7s ease-in-out;
  a{
      color: #B84989;
  }
}
.answers1 {
margin: 5px 0 0 4rem;
max-height: 0;
z-index: 0;
position: relative;
opacity: 0;
-webkit-transition: .7s ease-in-out;
-moz-transition: .7s ease-in-out;
-o-transition: .7s ease-in-out;
transition: .7s ease-in-out;
}
.answers2 {
margin: 5px 0 0 4rem;
max-height: 0;
z-index: 0;
position: relative;
opacity: 0;
-webkit-transition: .7s ease-in-out;
-moz-transition: .7s ease-in-out;
-o-transition: .7s ease-in-out;
transition: .7s ease-in-out;
}
.answers3 {
margin: 5px 0 0 4rem;
max-height: 0;
z-index: 0;
position: relative;
opacity: 0;
-webkit-transition: .7s ease-in-out;
-moz-transition: .7s ease-in-out;
-o-transition: .7s ease-in-out;
transition: .7s ease-in-out;
}
.answers4 {
margin: 5px 0 0 4rem;
max-height: 0;
z-index: 0;
position: relative;
opacity: 0;
-webkit-transition: .7s ease-in-out;
-moz-transition: .7s ease-in-out;
-o-transition: .7s ease-in-out;
transition: .7s ease-in-out;
}
.answers5 {
margin: 5px 0 0 4rem;
max-height: 0;
z-index: 0;
position: relative;
opacity: 0;
-webkit-transition: .7s ease-in-out;
-moz-transition: .7s ease-in-out;
-o-transition: .7s ease-in-out;
transition: .7s ease-in-out;
}
.answers6 {
margin: 5px 0 0 4rem;
max-height: 0;
z-index: 0;
position: relative;
opacity: 0;
-webkit-transition: .7s ease-in-out;
-moz-transition: .7s ease-in-out;
-o-transition: .7s ease-in-out;
transition: .7s ease-in-out;
}
.answers7 {
margin: 5px 0 0 4rem;
max-height: 0;
z-index: 0;
position: relative;
opacity: 0;
-webkit-transition: .7s ease-in-out;
-moz-transition: .7s ease-in-out;
-o-transition: .7s ease-in-out;
transition: .7s ease-in-out;
}
.answers8 {
margin: 5px 0 0 4rem;
max-height: 0;
z-index: 0;
position: relative;
opacity: 0;
-webkit-transition: .7s ease-in-out;
-moz-transition: .7s ease-in-out;
-o-transition: .7s ease-in-out;
transition: .7s ease-in-out;
}
.answers9 {
margin: 5px 0 0 4rem;
max-height: 0;
z-index: 0;
position: relative;
opacity: 0;
-webkit-transition: .7s ease-in-out;
-moz-transition: .7s ease-in-out;
-o-transition: .7s ease-in-out;
transition: .7s ease-in-out;
}
.answers10 {
margin: 5px 0 0 4rem;
max-height: 0;
z-index: 0;
position: relative;
opacity: 0;
-webkit-transition: .7s ease-in-out;
-moz-transition: .7s ease-in-out;
-o-transition: .7s ease-in-out;
transition: .7s ease-in-out;
}
.answers11 {
margin: 5px 0 0 4rem;
max-height: 0;
z-index: 0;
position: relative;
opacity: 0;
-webkit-transition: .7s ease-in-out;
-moz-transition: .7s ease-in-out;
-o-transition: .7s ease-in-out;
transition: .7s ease-in-out;
}
.answers12 {
margin: 5px 0 0 4rem;
max-height: 0;
z-index: 0;
position: relative;
opacity: 0;
-webkit-transition: .7s ease-in-out;
-moz-transition: .7s ease-in-out;
-o-transition: .7s ease-in-out;
transition: .7s ease-in-out;
}
.answers13 {
margin: 5px 0 0 4rem;
max-height: 0;
z-index: 0;
position: relative;
opacity: 0;
-webkit-transition: .7s ease-in-out;
-moz-transition: .7s ease-in-out;
-o-transition: .7s ease-in-out;
transition: .7s ease-in-out;
}
.answers14 {
margin: 5px 0 0 4rem;
max-height: 0;
z-index: 0;
position: relative;
opacity: 0;
-webkit-transition: .7s ease-in-out;
-moz-transition: .7s ease-in-out;
-o-transition: .7s ease-in-out;
transition: .7s ease-in-out;
}
.answers15 {
margin: 5px 0 0 4rem;
max-height: 0;
z-index: 0;
position: relative;
opacity: 0;
-webkit-transition: .7s ease-in-out;
-moz-transition: .7s ease-in-out;
-o-transition: .7s ease-in-out;
transition: .7s ease-in-out;
}
.questions:checked ~ .answers{
opacity: 1;
padding: 15px;
max-height: 100%;
}
.questions1:checked ~ .answers1{
opacity: 1;
padding: 15px;
max-height: 100%;
}
.questions2:checked ~ .answers2{
opacity: 1;
padding: 15px;
max-height: 100%;
}
.questions3:checked ~ .answers3{
opacity: 1;
padding: 15px;
max-height: 100%;
}
.questions4:checked ~ .answers4{
opacity: 1;
padding: 15px;
max-height: 100%;
}
.questions5:checked ~ .answers5{
opacity: 1;
padding: 15px;
max-height: 100%;
}
.questions6:checked ~ .answers6{
opacity: 1;
padding: 15px;
max-height: 100%;
}
.questions7:checked ~ .answers7{
opacity: 1;
padding: 15px;
max-height: 100%;
}
.questions8:checked ~ .answers8{
opacity: 1;
padding: 15px;
max-height: 100%;
}
.questions9:checked ~ .answers9{
opacity: 1;
padding: 15px;
max-height: 100%;
}
.questions10:checked ~ .answers10{
opacity: 1;
padding: 15px;
max-height: 100%;
}
.questions11:checked ~ .answers11{
opacity: 1;
padding: 15px;
max-height: 100%;
}
.questions12:checked ~ .answers12{
opacity: 1;
padding: 15px;
max-height: 100%;
}
.questions13:checked ~ .answers13{
opacity: 1;
padding: 15px;
max-height: 100%;
}
.questions14:checked ~ .answers14{
opacity: 1;
padding: 15px;
max-height: 100%;
}
.questions15:checked ~ .answers15{
opacity: 1;
padding: 15px;
max-height: 100%;
}

.plus {
position: absolute;
right: 27rem;
z-index: 0;
color: #AE4075;
font-size: 2em;
line-height: 100%;
margin-top: 5px;
-webkit-transition: .3s ease-in-out;
-moz-transition: .3s ease-in-out;
-o-transition: .3s ease-in-out;
transition: .3s ease-in-out;
}
.plusAnswer {
position: absolute;
color: #AE4075;
right: 3rem;
z-index: 0;
font-size: 2em;
line-height: 100%;
-webkit-transition: .3s ease-in-out;
-moz-transition: .3s ease-in-out;
-o-transition: .3s ease-in-out;
transition: .3s ease-in-out;
}
.plusAnswer1 {
position: absolute;
color: #AE4075;
right: 3rem;
z-index: 0;
font-size: 2em;
line-height: 100%;
-webkit-transition: .3s ease-in-out;
-moz-transition: .3s ease-in-out;
-o-transition: .3s ease-in-out;
transition: .3s ease-in-out;
}
.plusAnswer2 {
position: absolute;
right: 3rem;
color: #AE4075;
z-index: 0;
font-size: 2em;
line-height: 100%;
-webkit-transition: .3s ease-in-out;
-moz-transition: .3s ease-in-out;
-o-transition: .3s ease-in-out;
transition: .3s ease-in-out;
}
.plusAnswer3 {
position: absolute;
right: 3rem;
z-index: 0;
color: #AE4075;
font-size: 2em;
line-height: 100%;
-webkit-transition: .3s ease-in-out;
-moz-transition: .3s ease-in-out;
-o-transition: .3s ease-in-out;
transition: .3s ease-in-out;
}
.plusAnswer4 {
position: absolute;
right: 3rem;
z-index: 0;
font-size: 2em;
color: #AE4075;
line-height: 100%;
-webkit-transition: .3s ease-in-out;
-moz-transition: .3s ease-in-out;
-o-transition: .3s ease-in-out;
transition: .3s ease-in-out;
}
.plusAnswer5 {
position: absolute;
color: #AE4075;
right: 3rem;
z-index: 0;
font-size: 2em;
line-height: 100%;
-webkit-transition: .3s ease-in-out;
-moz-transition: .3s ease-in-out;
-o-transition: .3s ease-in-out;
transition: .3s ease-in-out;
}
.plusAnswer6 {
position: absolute;
right: 3rem;
z-index: 0;
color: #AE4075;
font-size: 2em;
line-height: 100%;
-webkit-transition: .3s ease-in-out;
-moz-transition: .3s ease-in-out;
-o-transition: .3s ease-in-out;
transition: .3s ease-in-out;
}
.plusAnswer7 {
position: absolute;
right: 3rem;
color: #AE4075;
z-index: 0;
font-size: 2em;
line-height: 100%;
-webkit-transition: .3s ease-in-out;
-moz-transition: .3s ease-in-out;
-o-transition: .3s ease-in-out;
transition: .3s ease-in-out;
}
.plusAnswer8 {
position: absolute;
right: 3rem;
z-index: 0;
color: #AE4075;
font-size: 2em;
line-height: 100%;
-webkit-transition: .3s ease-in-out;
-moz-transition: .3s ease-in-out;
-o-transition: .3s ease-in-out;
transition: .3s ease-in-out;
}
.plusAnswer9 {
position: absolute;
right: 3rem;
z-index: 0;
color: #AE4075;
font-size: 2em;
line-height: 100%;
-webkit-transition: .3s ease-in-out;
-moz-transition: .3s ease-in-out;
-o-transition: .3s ease-in-out;
transition: .3s ease-in-out;
}
.plusAnswer10 {
position: absolute;
right: 3rem;
z-index: 0;
color: #AE4075;
font-size: 2em;
line-height: 100%;
-webkit-transition: .3s ease-in-out;
-moz-transition: .3s ease-in-out;
-o-transition: .3s ease-in-out;
transition: .3s ease-in-out;
}
.plusAnswer11 {
position: absolute;
right: 3rem;
z-index: 0;
color: #AE4075;
font-size: 2em;
line-height: 100%;
-webkit-transition: .3s ease-in-out;
-moz-transition: .3s ease-in-out;
-o-transition: .3s ease-in-out;
transition: .3s ease-in-out;
}
.plusAnswer12 {
position: absolute;
right: 3rem;
z-index: 0;
font-size: 2em;
color: #AE4075;
line-height: 100%;
-webkit-transition: .3s ease-in-out;
-moz-transition: .3s ease-in-out;
-o-transition: .3s ease-in-out;
transition: .3s ease-in-out;
}
.plusAnswer13 {
position: absolute;
right: 3rem;
z-index: 0;
font-size: 2em;
color: #AE4075;
line-height: 100%;
-webkit-transition: .3s ease-in-out;
-moz-transition: .3s ease-in-out;
-o-transition: .3s ease-in-out;
transition: .3s ease-in-out;
}
.plusAnswer14 {
position: absolute;
right: 3rem;
color: #AE4075;
z-index: 0;
font-size: 2em;
line-height: 100%;
-webkit-transition: .3s ease-in-out;
-moz-transition: .3s ease-in-out;
-o-transition: .3s ease-in-out;
transition: .3s ease-in-out;
}
.plusAnswer15 {
position: absolute;
right: 3rem;
z-index: 0;
font-size: 2em;
color: #AE4075;
line-height: 100%;
-webkit-transition: .3s ease-in-out;
-moz-transition: .3s ease-in-out;
-o-transition: .3s ease-in-out;
transition: .3s ease-in-out;
}

.questions:checked ~ .plus {
-webkit-transform: rotate(45deg);
-moz-transform: rotate(45deg);
-o-transform: rotate(45deg);
transform: rotate(45deg);
}
.questions:checked ~ .plusAnswer {
-webkit-transform: rotate(45deg);
-moz-transform: rotate(45deg);
-o-transform: rotate(45deg);
transform: rotate(45deg);
}
.questions1:checked ~ .plusAnswer1 {
-webkit-transform: rotate(45deg);
-moz-transform: rotate(45deg);
-o-transform: rotate(45deg);
transform: rotate(45deg);
}
.questions2:checked ~ .plusAnswer2 {
-webkit-transform: rotate(45deg);
-moz-transform: rotate(45deg);
-o-transform: rotate(45deg);
transform: rotate(45deg);
}
.questions3:checked ~ .plusAnswer3 {
-webkit-transform: rotate(45deg);
-moz-transform: rotate(45deg);
-o-transform: rotate(45deg);
transform: rotate(45deg);
}
.questions4:checked ~ .plusAnswer4 {
-webkit-transform: rotate(45deg);
-moz-transform: rotate(45deg);
-o-transform: rotate(45deg);
transform: rotate(45deg);
}
.questions5:checked ~ .plusAnswer5 {
-webkit-transform: rotate(45deg);
-moz-transform: rotate(45deg);
-o-transform: rotate(45deg);
transform: rotate(45deg);
}
.questions6:checked ~ .plusAnswer6 {
-webkit-transform: rotate(45deg);
-moz-transform: rotate(45deg);
-o-transform: rotate(45deg);
transform: rotate(45deg);
}
.questions7:checked ~ .plusAnswer7 {
-webkit-transform: rotate(45deg);
-moz-transform: rotate(45deg);
-o-transform: rotate(45deg);
transform: rotate(45deg);
}
.questions8:checked ~ .plusAnswer8 {
-webkit-transform: rotate(45deg);
-moz-transform: rotate(45deg);
-o-transform: rotate(45deg);
transform: rotate(45deg);
}
.questions9:checked ~ .plusAnswer9 {
-webkit-transform: rotate(45deg);
-moz-transform: rotate(45deg);
-o-transform: rotate(45deg);
transform: rotate(45deg);
}
.questions10:checked ~ .plusAnswer10 {
-webkit-transform: rotate(45deg);
-moz-transform: rotate(45deg);
-o-transform: rotate(45deg);
transform: rotate(45deg);
}
.questions11:checked ~ .plusAnswer11 {
-webkit-transform: rotate(45deg);
-moz-transform: rotate(45deg);
-o-transform: rotate(45deg);
transform: rotate(45deg);
}
.questions12:checked ~ .plusAnswer12 {
-webkit-transform: rotate(45deg);
-moz-transform: rotate(45deg);
-o-transform: rotate(45deg);
transform: rotate(45deg);
}
.questions13:checked ~ .plusAnswer13 {
-webkit-transform: rotate(45deg);
-moz-transform: rotate(45deg);
-o-transform: rotate(45deg);
transform: rotate(45deg);
}
.questions14:checked ~ .plusAnswer14 {
-webkit-transform: rotate(45deg);
-moz-transform: rotate(45deg);
-o-transform: rotate(45deg);
transform: rotate(45deg);
}
.questions15:checked ~ .plusAnswer15 {
-webkit-transform: rotate(45deg);
-moz-transform: rotate(45deg);
-o-transform: rotate(45deg);
transform: rotate(45deg);
}

.questions{
display:none;
}
.questions1{
display:none;
}
.questions2{
display:none;
}
.questions3{
display:none;
}
.questions4{
display:none;
}
.questions5{
display:none;
}
.questions6{
display:none;
}
.questions7{
display:none;
}
.questions8{
display:none;
}
.questions9{
display:none;
}
.questions10{
display:none;
}
.questions11{
display:none;
}
.questions12{
display:none;
}
.questions13{
display:none;
}
.questions14{
display:none;
}
.questions15{
display:none;
}
.txtAnswer{
  margin: 0;
  width: 90%;
  a{
      color: #B84989;
  }
}
.pyFaqs {
padding-top: 2rem !important;
padding-bottom: 0 !important;
}
.row-Faqs {
--bs-gutter-x: 2rem;
--bs-gutter-y: 0;
display: -webkit-box;
display: -ms-flexbox;
display: flex;
-ms-flex-wrap: wrap;
flex-wrap: wrap;
margin-top: calc(var(--bs-gutter-y) * -1);
margin-left: calc(var(--bs-gutter-x) / -2);
margin-right: calc(var(--bs-gutter-x) / -2);
}

.row-Faqs > * {
-ms-flex-negative: 0;
flex-shrink: 0;
width: 100%;
max-width: 100%;
padding-right: calc(var(--bs-gutter-x) / 2);
margin-top: var(--bs-gutter-y);
}
.mt-8-Faqs {
margin-top: 7.5rem !important;
}
.mx-9-Faqs {
margin-left: 10rem !important;
margin-right: 10rem !important;
}
.mt-3-Faqs {
margin-top: 1rem !important;
}
.fs-4-Faqs {
font-size: 2.0736rem !important;
margin: 0 30rem;
}
@media screen and (max-width: 768px) {
    .outside-question{
        font-size: 1rem;
        color: $black;
    }
    .pyFaqs{
        padding-bottom: 0 !important;
        padding-top: 0 !important;
    }
    .row-Faqs{
        margin-left: 0;
        margin-right: 0;
    }
    .mt-8-Faqs{
        margin: 0 !important;
    }
    .mx-9-Faqs{
        margin: 0 !important;
    }
    .mt-3-Faqs{
        margin: 10rem 0 0 0 !important;
    }
    .fs-4-Faqs{
        font-size: 30px !important;
        text-align: center;
        margin: 0 !important;
        margin-bottom: 3rem !important;
    }
    .cardsFAQs{    
        border: none !important;
        max-width: 11rem !important;
        margin: 0;
        .card-img{
            width: 11rem !important;
            padding:7px;
        }
        .card-img-select{
            width: 11rem !important;
            display:none;
            padding:5px;
        }
        .card-icon{
            width: 1.7rem !important;
        }
        a{
            color: $black !important;
        }
        p{
            margin-bottom: 0 !important;
        }
        .title{
            font-size: 0.8rem !important;
            padding: 10px 25px 0 25px !important;
        }
        .card-img-overlay{
            padding: 0 !important;
        }
    }
  .cardsFAQs:hover{
      .card-img{
          padding:0;
          transition: 1s;
      }
      .card-img-select{
          padding:0;
          transition: 1s;
      }
      .card-icon{
          width: 2rem !important;
          transition: 1s;
      }
      .title{
          font-size: 1.2rem;
          transition: 1s;
      }
  }
  .container-faqs{
      font-size: 0.85rem;
      margin: 0;
      padding: 0 0 0 50px !important;
      width: 90%;
  }
  .faqs-container{
      justify-content: center;
  }
  .answers{
      margin: 5px 0 0 1rem;
  }
  .answers1{
      margin: 5px 0 0 1rem;
  }
  .answers2{
      margin: 5px 0 0 1rem;
  }
  .answers3{
      margin: 5px 0 0 1rem;
  }
  .answers4{
      margin: 5px 0 0 1rem;
  }
  .answers5{
      margin: 5px 0 0 1rem;
  }
  .answers6{
      margin: 5px 0 0 1rem;
  }
  .answers7{
      margin: 5px 0 0 1rem;
  }
  .answers8{
      margin: 5px 0 0 1rem;
  }
  .answers9{
      margin: 5px 0 0 1rem;
  }
  .answers10{
      margin: 5px 0 0 1rem;
  }
  .answers11{
      margin: 5px 0 0 1rem;
  }
  .answers12{
      margin: 5px 0 0 1rem;
  }
  .answers13{
      margin: 5px 0 0 1rem;
  }
  .answers14{
      margin: 5px 0 0 1rem;
  }
  .answers15{
      margin: 5px 0 0 1rem;
  }
  .question{
      padding: 10px 0 !important;
      padding-right: 30px !important;
      width: 100%;
  }
  .plus{
      margin-top: 8px;
      right: 2.5rem;
  }
  .plusAnswer{
      right: 1rem;
      margin-top: 8px;
  }
  .plusAnswer1{
      right: 1rem;
      margin-top: 8px;
  }
  .plusAnswer2{
      right: 1rem;
      margin-top: 8px;
  }
  .plusAnswer3{
      right: 1rem;
      margin-top: 8px;
  }
  .plusAnswer4{
      right: 1rem;
      margin-top: 8px;
  }
  .plusAnswer5{
      right: 1rem;
      margin-top: 8px;
  }
  .plusAnswer6{
      right: 1rem;
      margin-top: 8px;
  }
  .plusAnswer7{
      right: 1rem;
      margin-top: 8px;
  }
  .plusAnswer8{
      right: 1rem;
      margin-top: 8px;
  }
  .plusAnswer9{
      right: 1rem;
      margin-top: 8px;
  }
  .plusAnswer10{
      right: 1rem;
      margin-top: 8px;
  }
  .plusAnswer11{
      right: 1rem;
      margin-top: 8px;
  }
  .plusAnswer12{
      right: 1rem;
      margin-top: 8px;
  }
  .plusAnswer13{
      right: 1rem;
      margin-top: 8px;
  }
  .plusAnswer14{
      right: 1rem;
      margin-top: 8px;
  }
  .plusAnswer15{
      right: 1rem;
      margin-top: 8px;
  }
  .questions:checked ~ .answers{
    opacity: 1;
    padding: 15px 0 0 10px;
    max-height: 100%;
    }
    .questions1:checked ~ .answers1{
    opacity: 1;
    padding: 15px 0 0 10px;
    max-height: 100%;
    }
    .questions2:checked ~ .answers2{
    opacity: 1;
    padding: 15px 0 0 10px;
    max-height: 100%;
    }
    .questions3:checked ~ .answers3{
    opacity: 1;
    padding: 15px 0 0 10px;
    max-height: 100%;
    }
    .questions4:checked ~ .answers4{
    opacity: 1;
    padding: 15px 0 0 10px;
    max-height: 100%;
    }
    .questions5:checked ~ .answers5{
    opacity: 1;
    padding: 15px 0 0 10px;
    max-height: 100%;
    }
    .questions6:checked ~ .answers6{
    opacity: 1;
    padding: 15px 0 0 10px;
    max-height: 100%;
    }
    .questions7:checked ~ .answers7{
    opacity: 1;
    padding: 15px 0 0 10px;
    max-height: 100%;
    }
    .questions8:checked ~ .answers8{
    opacity: 1;
    padding: 15px 0 0 10px;
    max-height: 100%;
    }
    .questions9:checked ~ .answers9{
    opacity: 1;
    padding: 15px 0 0 10px;
    max-height: 100%;
    }
    .questions10:checked ~ .answers10{
    opacity: 1;
    padding: 15px 0 0 10px;
    max-height: 100%;
    }
    .questions11:checked ~ .answers11{
    opacity: 1;
    padding: 15px 0 0 10px;
    max-height: 100%;
    }
    .questions12:checked ~ .answers12{
    opacity: 1;
    padding: 15px 0 0 10px;
    max-height: 100%;
    }
    .questions13:checked ~ .answers13{
    opacity: 1;
    padding: 15px 0 0 10px;
    max-height: 100%;
    }
    .questions14:checked ~ .answers14{
    opacity: 1;
    padding: 15px 0 0 10px;
    max-height: 100%;
    }
    .questions15:checked ~ .answers15{
    opacity: 1;
    padding: 15px 0 0 10px;
    max-height: 100%;
    }
}
@media screen and (min-width: 1024px) and (max-width:1567px) {
    .outside-question{
        font-size: 1rem;
        color: $black;
    }

    .faqs-section {
        min-height: calc(100vh - 472.16px);
    }

    .pyFaqs{
        padding-bottom: 0 !important;
        padding-top: 0 !important;
    }
    .row-Faqs{
        margin-left: 0;
        margin-right: 0;
    }
    .mt-8-Faqs{
       margin: 0 !important;
    }
    .mx-9-Faqs{
       margin: 0 !important;
    }
    .mt-3-Faqs{
        margin: 83.19px 0 0 0 !important;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .fs-4-Faqs{
        font-size: 30px !important;
        text-align: left;
        margin: 0 !important;
        margin-bottom: 3rem !important;

    }
    .cardsFAQs{    
        border: none !important;
        max-width: 11rem !important;
        .card-img{
            width: 11rem !important;
            padding:7px;
        }
        .card-img-select{
            width: 11rem !important;
            display:none;
            padding:5px;
        }
        .card-icon{
            width: 1.7rem !important;
        }
        a{
            color: $black !important;
        }
        p{
            margin-bottom: 0 !important;
        }
        .title{
            font-size: 0.8rem !important;
            padding: 10px 25px 0 25px !important;
        }
        .card-img-overlay{
            padding: 0 !important;
        }
    }
    .cardsFAQs:hover{
        .card-img{
            padding:0;
            transition: 1s;
        }
        .card-img-select{
            padding:0;
            transition: 1s;
        }
        .card-icon{
            width: 2rem !important;
            transition: 1s;
        }
        .title{
            font-size: 1.2rem;
            transition: 1s;
        }
    }
    .container-faqs{
        font-size: 0.85rem;
        margin: 0;
        padding: 0 2rem 0 12rem !important;
        width: 90%;
    }
    .faqs-container{
        justify-content: center;
    }
    .answers{
        margin: 5px 0 0 1rem;
    }
    .answers1{
        margin: 5px 0 0 1rem;
    }
    .answers2{
        margin: 5px 0 0 1rem;
    }
    .answers3{
        margin: 5px 0 0 1rem;
    }
    .answers4{
        margin: 5px 0 0 1rem;
    }
    .answers5{
        margin: 5px 0 0 1rem;
    }
    .answers6{
        margin: 5px 0 0 1rem;
    }
    .answers7{
        margin: 5px 0 0 1rem;
    }
    .answers8{
        margin: 5px 0 0 1rem;
    }
    .answers9{
        margin: 5px 0 0 1rem;
    }
    .answers10{
        margin: 5px 0 0 1rem;
    }
    .answers11{
        margin: 5px 0 0 1rem;
    }
    .answers12{
        margin: 5px 0 0 1rem;
    }
    .answers13{
        margin: 5px 0 0 1rem;
    }
    .answers14{
        margin: 5px 0 0 1rem;
    }
    .answers15{
        margin: 5px 0 0 1rem;
    }
    .question{
        padding: 10px 0 !important;
        padding-right: 30px !important;
        width: 100%;
    }
    .plus{
        margin-top: 8px;
        right: 12rem;
    }
    .plusAnswer{
        right: 2rem;
        margin-top: 8px;
    }
    .plusAnswer1{
        right: 2rem;
        margin-top: 8px;
    }
    .plusAnswer2{
        right: 2rem;
        margin-top: 8px;
    }
    .plusAnswer3{
        right: 2rem;
        margin-top: 8px;
    }
    .plusAnswer4{
        right: 2rem;
        margin-top: 8px;
    }
    .plusAnswer5{
        right: 2rem;
        margin-top: 8px;
    }
    .plusAnswer6{
        right: 2rem;
        margin-top: 8px;
    }
    .plusAnswer7{
        right: 2rem;
        margin-top: 8px;
    }
    .plusAnswer8{
        right: 2rem;
        margin-top: 8px;
    }
    .plusAnswer9{
        right: 2rem;
        margin-top: 8px;
    }
    .plusAnswer10{
        right: 2rem;
        margin-top: 8px;
    }
    .plusAnswer11{
        right: 2rem;
        margin-top: 8px;
    }
    .plusAnswer12{
        right: 2rem;
        margin-top: 8px;
    }
    .plusAnswer13{
        right: 2rem;
        margin-top: 8px;
    }
    .plusAnswer14{
        right: 2rem;
        margin-top: 8px;
    }
    .plusAnswer15{
        right: 2rem;
        margin-top: 8px;
    }
    .questions:checked ~ .answers{
      opacity: 1;
      padding: 15px 0 0 10px;
      max-height: 100%;
      }
      .questions1:checked ~ .answers1{
      opacity: 1;
      padding: 15px 0 0 10px;
      max-height: 100%;
      }
      .questions2:checked ~ .answers2{
      opacity: 1;
      padding: 15px 0 0 10px;
      max-height: 100%;
      }
      .questions3:checked ~ .answers3{
      opacity: 1;
      padding: 15px 0 0 10px;
      max-height: 100%;
      }
      .questions4:checked ~ .answers4{
      opacity: 1;
      padding: 15px 0 0 10px;
      max-height: 100%;
      }
      .questions5:checked ~ .answers5{
      opacity: 1;
      padding: 15px 0 0 10px;
      max-height: 100%;
      }
      .questions6:checked ~ .answers6{
      opacity: 1;
      padding: 15px 0 0 10px;
      max-height: 100%;
      }
      .questions7:checked ~ .answers7{
      opacity: 1;
      padding: 15px 0 0 10px;
      max-height: 100%;
      }
      .questions8:checked ~ .answers8{
      opacity: 1;
      padding: 15px 0 0 10px;
      max-height: 100%;
      }
      .questions9:checked ~ .answers9{
      opacity: 1;
      padding: 15px 0 0 10px;
      max-height: 100%;
      }
      .questions10:checked ~ .answers10{
      opacity: 1;
      padding: 15px 0 0 10px;
      max-height: 100%;
      }
      .questions11:checked ~ .answers11{
      opacity: 1;
      padding: 15px 0 0 10px;
      max-height: 100%;
      }
      .questions12:checked ~ .answers12{
      opacity: 1;
      padding: 15px 0 0 10px;
      max-height: 100%;
      }
      .questions13:checked ~ .answers13{
      opacity: 1;
      padding: 15px 0 0 10px;
      max-height: 100%;
      }
      .questions14:checked ~ .answers14{
      opacity: 1;
      padding: 15px 0 0 10px;
      max-height: 100%;
      }
      .questions15:checked ~ .answers15{
      opacity: 1;
      padding: 15px 0 0 10px;
      max-height: 100%;
      }
  }
@media screen and (min-width:768px) and (max-width: 1024px) {
    .outside-question{
        font-size: 1rem;
        color: $black;
    }
    .faqs-section {
        min-height: calc(100vh - 472.16px);
    }
    .pyFaqs{
        padding-bottom: 0 !important;
        padding-top: 0 !important;
    }
    .row-Faqs{
        margin-left: 0;
        margin-right: 0;
    }
    .mt-8-Faqs{
       margin: 0 !important;
    }
    .mx-9-Faqs{
       margin: 0 !important;
    }
    .mt-3-Faqs{
        margin: 99.38px 0 0 0 !important;
    }
    .fs-4-Faqs{
        font-size: 30px !important;
        text-align: center;
        margin: 0 !important;
        margin-bottom: 3rem !important;
    }
    .cardsFAQs{    
        border: none !important;
        max-width: 11rem !important;
        .card-img{
            width: 11rem !important;
            padding:7px;
        }
        .card-img-select{
            width: 11rem !important;
            display:none;
            padding:5px;
        }
        .card-icon{
            width: 1.7rem !important;
        }
        a{
            color: $black !important;
        }
        p{
            margin-bottom: 0 !important;
        }
        .title{
            font-size: 0.8rem !important;
            padding: 10px 25px 0 25px !important;
        }
        .card-img-overlay{
            padding: 0 !important;
        }
    }
    .cardsFAQs:hover{
        .card-img{
            padding:0;
            transition: 1s;
        }
        .card-img-select{
            padding:0;
            transition: 1s;
        }
        .card-icon{
            width: 2rem !important;
            transition: 1s;
        }
        .title{
            font-size: 1.2rem;
            transition: 1s;
        }
    }
    .container-faqs{
        font-size: 0.85rem;
        margin: 0 !important;
        padding: 0 2rem 0 5rem !important;
        width: 95% !important;
    }
    .faqs-container{
        justify-content: center;
    }
    .answers{
        margin: 5px 0 0 1rem;
    }
    .answers1{
        margin: 5px 0 0 1rem;
    }
    .answers2{
        margin: 5px 0 0 1rem;
    }
    .answers3{
        margin: 5px 0 0 1rem;
    }
    .answers4{
        margin: 5px 0 0 1rem;
    }
    .answers5{
        margin: 5px 0 0 1rem;
    }
    .answers6{
        margin: 5px 0 0 1rem;
    }
    .answers7{
        margin: 5px 0 0 1rem;
    }
    .answers8{
        margin: 5px 0 0 1rem;
    }
    .answers9{
        margin: 5px 0 0 1rem;
    }
    .answers10{
        margin: 5px 0 0 1rem;
    }
    .answers11{
        margin: 5px 0 0 1rem;
    }
    .answers12{
        margin: 5px 0 0 1rem;
    }
    .answers13{
        margin: 5px 0 0 1rem;
    }
    .answers14{
        margin: 5px 0 0 1rem;
    }
    .answers15{
        margin: 5px 0 0 1rem;
    }
    .question{
        padding: 10px 0 !important;
        padding-right: 30px !important;
        width: 100%;
    }
    .plus{
        margin-top: 8px;
        right: 7rem;
    }
    .plusAnswer{
        right: 3rem;
        margin-top: 8px;
    }
    .plusAnswer1{
        right: 3rem;
        margin-top: 8px;
    }
    .plusAnswer2{
        right: 3rem;
        margin-top: 8px;
    }
    .plusAnswer3{
        right: 3rem;
        margin-top: 8px;
    }
    .plusAnswer4{
        right: 3rem;
        margin-top: 8px;
    }
    .plusAnswer5{
        right: 3rem;
        margin-top: 8px;
    }
    .plusAnswer6{
        right: 3rem;
        margin-top: 8px;
    }
    .plusAnswer7{
        right: 3rem;
        margin-top: 8px;
    }
    .plusAnswer8{
        right: 3rem;
        margin-top: 8px;
    }
    .plusAnswer9{
        right: 3rem;
        margin-top: 8px;
    }
    .plusAnswer10{
        right: 3rem;
        margin-top: 8px;
    }
    .plusAnswer11{
        right: 3rem;
        margin-top: 8px;
    }
    .plusAnswer12{
        right: 3rem;
        margin-top: 8px;
    }
    .plusAnswer13{
        right: 3rem;
        margin-top: 8px;
    }
    .plusAnswer14{
        right: 3rem;
        margin-top: 8px;
    }
    .plusAnswer15{
        right: 3rem;
        margin-top: 8px;
    }
    .questions:checked ~ .answers{
      opacity: 1;
      padding: 15px 0 0 10px;
      max-height: 100%;
      }
      .questions1:checked ~ .answers1{
      opacity: 1;
      padding: 15px 0 0 10px;
      max-height: 100%;
      }
      .questions2:checked ~ .answers2{
      opacity: 1;
      padding: 15px 0 0 10px;
      max-height: 100%;
      }
      .questions3:checked ~ .answers3{
      opacity: 1;
      padding: 15px 0 0 10px;
      max-height: 100%;
      }
      .questions4:checked ~ .answers4{
      opacity: 1;
      padding: 15px 0 0 10px;
      max-height: 100%;
      }
      .questions5:checked ~ .answers5{
      opacity: 1;
      padding: 15px 0 0 10px;
      max-height: 100%;
      }
      .questions6:checked ~ .answers6{
      opacity: 1;
      padding: 15px 0 0 10px;
      max-height: 100%;
      }
      .questions7:checked ~ .answers7{
      opacity: 1;
      padding: 15px 0 0 10px;
      max-height: 100%;
      }
      .questions8:checked ~ .answers8{
      opacity: 1;
      padding: 15px 0 0 10px;
      max-height: 100%;
      }
      .questions9:checked ~ .answers9{
      opacity: 1;
      padding: 15px 0 0 10px;
      max-height: 100%;
      }
      .questions10:checked ~ .answers10{
      opacity: 1;
      padding: 15px 0 0 10px;
      max-height: 100%;
      }
      .questions11:checked ~ .answers11{
      opacity: 1;
      padding: 15px 0 0 10px;
      max-height: 100%;
      }
      .questions12:checked ~ .answers12{
      opacity: 1;
      padding: 15px 0 0 10px;
      max-height: 100%;
      }
      .questions13:checked ~ .answers13{
      opacity: 1;
      padding: 15px 0 0 10px;
      max-height: 100%;
      }
      .questions14:checked ~ .answers14{
      opacity: 1;
      padding: 15px 0 0 10px;
      max-height: 100%;
      }
      .questions15:checked ~ .answers15{
      opacity: 1;
      padding: 15px 0 0 10px;
      max-height: 100%;
      }
  }
  @media screen and (min-width: 528px) and (max-width: 703px) {
    .outside-question{
        font-size: 1rem;
        color: $black;
    }
    .pyFaqs{
        padding-bottom: 0 !important;
        padding-top: 0 !important;
    }
    .row-Faqs{
        margin-left: 0;
        margin-right: 0;
    }
    .mt-8-Faqs{
       margin: 0 !important;
    }
    .mx-9-Faqs{
       margin: 0 !important;
       display: flex;
       justify-content: center;
    }
    .mt-3-Faqs{
        margin: 95px 0 0 0 !important;
        width: 500px;
    }
    .fs-4-Faqs{
        font-size: 30px !important;
        text-align: center;
        margin-bottom: 3rem;
    }
    .cardsFAQs{    
        border: none !important;
        max-width: 11rem !important;
        margin: 0;
        .card-img{
            width: 11rem !important;
            padding:7px;
        }
        .card-img-select{
            width: 11rem !important;
            display:none;
            padding:5px;
        }
        .card-icon{
            width: 1.7rem !important;
        }
        a{
            color: $black !important;
        }
        p{
            margin-bottom: 0 !important;
        }
        .title{
            font-size: 0.8rem !important;
            padding: 10px 25px 0 25px !important;
        }
        .card-img-overlay{
            padding: 0 !important;
        }
    }
    .cardsFAQs:hover{
        .card-img{
            padding:0;
            transition: 1s;
        }
        .card-img-select{
            padding:0;
            transition: 1s;
        }
        .card-icon{
            width: 2rem !important;
            transition: 1s;
        }
        .title{
            font-size: 1.2rem;
            transition: 1s;
        }
    }
    .container-faqs{
        font-size: 0.85rem;
        margin: 0;
        padding: 0 0 0 20px;
        width: 90%;
    }
    .faqs-container{
        justify-content: center;
    }
    .answers{
        margin: 5px 0 0 1rem;
    }
    .answers1{
        margin: 5px 0 0 1rem;
    }
    .answers2{
        margin: 5px 0 0 1rem;
    }
    .answers3{
        margin: 5px 0 0 1rem;
    }
    .answers4{
        margin: 5px 0 0 1rem;
    }
    .answers5{
        margin: 5px 0 0 1rem;
    }
    .answers6{
        margin: 5px 0 0 1rem;
    }
    .answers7{
        margin: 5px 0 0 1rem;
    }
    .answers8{
        margin: 5px 0 0 1rem;
    }
    .answers9{
        margin: 5px 0 0 1rem;
    }
    .answers10{
        margin: 5px 0 0 1rem;
    }
    .answers11{
        margin: 5px 0 0 1rem;
    }
    .answers12{
        margin: 5px 0 0 1rem;
    }
    .answers13{
        margin: 5px 0 0 1rem;
    }
    .answers14{
        margin: 5px 0 0 1rem;
    }
    .answers15{
        margin: 5px 0 0 1rem;
    }
    .question{
        padding: 10px 0 !important;
        padding-right: 30px !important;
        width: 100%;
    }
    .plus{
        margin-top: 8px;
        right: 2.5rem;
    }
    .plusAnswer{
        right: 1rem;
        margin-top: 8px;
    }
    .plusAnswer1{
        right: 1rem;
        margin-top: 8px;
    }
    .plusAnswer2{
        right: 1rem;
        margin-top: 8px;
    }
    .plusAnswer3{
        right: 1rem;
        margin-top: 8px;
    }
    .plusAnswer4{
        right: 1rem;
        margin-top: 8px;
    }
    .plusAnswer5{
        right: 1rem;
        margin-top: 8px;
    }
    .plusAnswer6{
        right: 1rem;
        margin-top: 8px;
    }
    .plusAnswer7{
        right: 1rem;
        margin-top: 8px;
    }
    .plusAnswer8{
        right: 1rem;
        margin-top: 8px;
    }
    .plusAnswer9{
        right: 1rem;
        margin-top: 8px;
    }
    .plusAnswer10{
        right: 1rem;
        margin-top: 8px;
    }
    .plusAnswer11{
        right: 1rem;
        margin-top: 8px;
    }
    .plusAnswer12{
        right: 1rem;
        margin-top: 8px;
    }
    .plusAnswer13{
        right: 1rem;
        margin-top: 8px;
    }
    .plusAnswer14{
        right: 1rem;
        margin-top: 8px;
    }
    .plusAnswer15{
        right: 1rem;
        margin-top: 8px;
    }
    .questions:checked ~ .answers{
      opacity: 1;
      padding: 15px 0 0 10px;
      max-height: 100%;
      }
      .questions1:checked ~ .answers1{
      opacity: 1;
      padding: 15px 0 0 10px;
      max-height: 100%;
      }
      .questions2:checked ~ .answers2{
      opacity: 1;
      padding: 15px 0 0 10px;
      max-height: 100%;
      }
      .questions3:checked ~ .answers3{
      opacity: 1;
      padding: 15px 0 0 10px;
      max-height: 100%;
      }
      .questions4:checked ~ .answers4{
      opacity: 1;
      padding: 15px 0 0 10px;
      max-height: 100%;
      }
      .questions5:checked ~ .answers5{
      opacity: 1;
      padding: 15px 0 0 10px;
      max-height: 100%;
      }
      .questions6:checked ~ .answers6{
      opacity: 1;
      padding: 15px 0 0 10px;
      max-height: 100%;
      }
      .questions7:checked ~ .answers7{
      opacity: 1;
      padding: 15px 0 0 10px;
      max-height: 100%;
      }
      .questions8:checked ~ .answers8{
      opacity: 1;
      padding: 15px 0 0 10px;
      max-height: 100%;
      }
      .questions9:checked ~ .answers9{
      opacity: 1;
      padding: 15px 0 0 10px;
      max-height: 100%;
      }
      .questions10:checked ~ .answers10{
      opacity: 1;
      padding: 15px 0 0 10px;
      max-height: 100%;
      }
      .questions11:checked ~ .answers11{
      opacity: 1;
      padding: 15px 0 0 10px;
      max-height: 100%;
      }
      .questions12:checked ~ .answers12{
      opacity: 1;
      padding: 15px 0 0 10px;
      max-height: 100%;
      }
      .questions13:checked ~ .answers13{
      opacity: 1;
      padding: 15px 0 0 10px;
      max-height: 100%;
      }
      .questions14:checked ~ .answers14{
      opacity: 1;
      padding: 15px 0 0 10px;
      max-height: 100%;
      }
      .questions15:checked ~ .answers15{
      opacity: 1;
      padding: 15px 0 0 10px;
      max-height: 100%;
      }
  }
  @media screen and (min-width: 708px) and (max-width: 943px) {
    .outside-question{
        font-size: 1rem;
        color: $black;
    }
    .pyFaqs{
        padding-bottom: 0 !important;
        padding-top: 0 !important;
    }
    .row-Faqs{
        margin-left: 0;
        margin-right: 0;
    }
    .mt-8-Faqs{
       margin: 0 !important;
    }
    .mx-9-Faqs{
       margin: 0 !important;
       display: flex;
       justify-content: center;
    }
    .mt-3-Faqs{
        margin: 10rem 0 0 0 !important;
        width: 500px;
    }
    .fs-4-Faqs{
        font-size: 30px !important;
        text-align: center;
        margin: 0 !important;
        margin-bottom: 3rem !important;
    }
    .cardsFAQs{    
        border: none !important;
        max-width: 11rem !important;
        margin: 0;
        .card-img{
            width: 11rem !important;
            padding:7px;
        }
        .card-img-select{
            width: 11rem !important;
            display:none;
            padding:5px;
        }
        .card-icon{
            width: 1.7rem !important;
        }
        a{
            color: $black !important;
        }
        p{
            margin-bottom: 0 !important;
        }
        .title{
            font-size: 0.8rem !important;
            padding: 10px 25px 0 25px !important;
        }
        .card-img-overlay{
            padding: 0 !important;
        }
    }
    .cardsFAQs:hover{
        .card-img{
            padding:0;
            transition: 1s;
        }
        .card-img-select{
            padding:0;
            transition: 1s;
        }
        .card-icon{
            width: 2rem !important;
            transition: 1s;
        }
        .title{
            font-size: 1.2rem;
            transition: 1s;
        }
    }
    .container-faqs{
        font-size: 0.85rem;
        margin: 0;
        padding: 0 0 0 3rem !important;
        width: 90%;
    }
    .faqs-container{
        justify-content: center;
    }
    .answers{
        margin: 5px 0 0 1rem;
    }
    .answers1{
        margin: 5px 0 0 1rem;
    }
    .answers2{
        margin: 5px 0 0 1rem;
    }
    .answers3{
        margin: 5px 0 0 1rem;
    }
    .answers4{
        margin: 5px 0 0 1rem;
    }
    .answers5{
        margin: 5px 0 0 1rem;
    }
    .answers6{
        margin: 5px 0 0 1rem;
    }
    .answers7{
        margin: 5px 0 0 1rem;
    }
    .answers8{
        margin: 5px 0 0 1rem;
    }
    .answers9{
        margin: 5px 0 0 1rem;
    }
    .answers10{
        margin: 5px 0 0 1rem;
    }
    .answers11{
        margin: 5px 0 0 1rem;
    }
    .answers12{
        margin: 5px 0 0 1rem;
    }
    .answers13{
        margin: 5px 0 0 1rem;
    }
    .answers14{
        margin: 5px 0 0 1rem;
    }
    .answers15{
        margin: 5px 0 0 1rem;
    }
    .question{
        padding: 10px 0 !important;
        padding-right: 30px !important;
        width: 100%;
    }
    .plus{
        margin-top: 8px;
        right: 5rem;
    }
    .plusAnswer{
        right: 4rem;
        margin-top: 8px;
    }
    .plusAnswer1{
        right: 4rem;
        margin-top: 8px;
    }
    .plusAnswer2{
        right: 4rem;
        margin-top: 8px;
    }
    .plusAnswer3{
        right: 4rem;
        margin-top: 8px;
    }
    .plusAnswer4{
        right: 4rem;
        margin-top: 8px;
    }
    .plusAnswer5{
        right: 4rem;
        margin-top: 8px;
    }
    .plusAnswer6{
        right: 4rem;
        margin-top: 8px;
    }
    .plusAnswer7{
        right: 4rem;
        margin-top: 8px;
    }
    .plusAnswer8{
        right: 4rem;
        margin-top: 8px;
    }
    .plusAnswer9{
        right: 4rem;
        margin-top: 8px;
    }
    .plusAnswer10{
        right: 4rem;
        margin-top: 8px;
    }
    .plusAnswer11{
        right: 4rem;
        margin-top: 8px;
    }
    .plusAnswer12{
        right: 4rem;
        margin-top: 8px;
    }
    .plusAnswer13{
        right: 4rem;
        margin-top: 8px;
    }
    .plusAnswer14{
        right: 4rem;
        margin-top: 8px;
    }
    .plusAnswer15{
        right: 4rem;
        margin-top: 8px;
    }
    .questions:checked ~ .answers{
      opacity: 1;
      padding: 15px 0 0 10px;
      max-height: 100%;
      }
      .questions1:checked ~ .answers1{
      opacity: 1;
      padding: 15px 0 0 10px;
      max-height: 100%;
      }
      .questions2:checked ~ .answers2{
      opacity: 1;
      padding: 15px 0 0 10px;
      max-height: 100%;
      }
      .questions3:checked ~ .answers3{
      opacity: 1;
      padding: 15px 0 0 10px;
      max-height: 100%;
      }
      .questions4:checked ~ .answers4{
      opacity: 1;
      padding: 15px 0 0 10px;
      max-height: 100%;
      }
      .questions5:checked ~ .answers5{
      opacity: 1;
      padding: 15px 0 0 10px;
      max-height: 100%;
      }
      .questions6:checked ~ .answers6{
      opacity: 1;
      padding: 15px 0 0 10px;
      max-height: 100%;
      }
      .questions7:checked ~ .answers7{
      opacity: 1;
      padding: 15px 0 0 10px;
      max-height: 100%;
      }
      .questions8:checked ~ .answers8{
      opacity: 1;
      padding: 15px 0 0 10px;
      max-height: 100%;
      }
      .questions9:checked ~ .answers9{
      opacity: 1;
      padding: 15px 0 0 10px;
      max-height: 100%;
      }
      .questions10:checked ~ .answers10{
      opacity: 1;
      padding: 15px 0 0 10px;
      max-height: 100%;
      }
      .questions11:checked ~ .answers11{
      opacity: 1;
      padding: 15px 0 0 10px;
      max-height: 100%;
      }
      .questions12:checked ~ .answers12{
      opacity: 1;
      padding: 15px 0 0 10px;
      max-height: 100%;
      }
      .questions13:checked ~ .answers13{
      opacity: 1;
      padding: 15px 0 0 10px;
      max-height: 100%;
      }
      .questions14:checked ~ .answers14{
      opacity: 1;
      padding: 15px 0 0 10px;
      max-height: 100%;
      }
      .questions15:checked ~ .answers15{
      opacity: 1;
      padding: 15px 0 0 10px;
      max-height: 100%;
      }
  }