.shapeup {
  margin-bottom: 0;
}

.services {
  display: flex;
  justify-content: center;
  padding-top: 150px;

  @media(max-width:760px) {
    padding-top: 80px;
}
}

.img-services{
  @media(max-width:760px) {
    width: 75vw;
}
  width: 50vw;
}

@include media-breakpoint-up(xxl) {
  .shapeup {
    margin-bottom:-305px;
  }
  
}
@include media-breakpoint-up(xl) {
  .shapeup {
    margin-bottom:-200px;
  }
  
}
.shapedown{
  margin-bottom: 0;
}

@include media-breakpoint-up(xxl) {
  .shapedown {
    margin-top:-280px;
  }
  
}

@include media-breakpoint-up(lg) {
  .shapedown {
    margin-top:-176px;
  }
  
}

.image-down{
  margin-bottom: 25px;
}

@include media-breakpoint-up(lg) {
  .image-down{
    margin-bottom:-230px;
  }
  
}

@include media-breakpoint-up(xxl) {
  .image-down{
    margin-bottom:-290px;
  }
  
}

.image-up{
  margin-top:-140px;
}
@include media-breakpoint-up(lg) {
  .image-up{
    margin-top:-100px;
  }
  
}

@include media-breakpoint-up(xl) {
  .image-up{
    margin-top:-140px;
  }
  
}

@include media-breakpoint-up(xxl) {
  .image-up{
    margin-top:-210px;
  }
  
}




// @include media-breakpoint-up(lg) {
//   .image-up{
//     margin-top:-140px;
//   }
  
// }




// @include media-breakpoint-up(lg) {
//   .image-up{
//     margin-top:-240px;
//   }
  
// }

